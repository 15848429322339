
import Vue from 'vue';
import LoggedIn from './LoggedIn.vue';
import EmbeddedMap from './Map/CountMap.vue';
import { Notification } from '@/services/notification';
import LocationWarning from './LocationWarning.vue';
export default Vue.extend({
  name: 'MapScreen',
  components: { LoggedIn, EmbeddedMap, LocationWarning },
  data() {
    return {
      fetching: false,
      errorMessage: '',
      watchIdentifier: 0,
    };
  },
  mounted(): void {
    this.watchIdentifier = this.getLocation();
  },
  destroyed(): void {
    if (this.watchIdentifier === 0) return;
    navigator.geolocation.clearWatch(this.watchIdentifier)
  },
  computed: {
    ready(): boolean {
      return !!this.$store.getters.location;
    },
  },
  methods: {
    getLocation(): number {
      this.fetching = true;
      let geoSuccess = (position: any) => {
        const location = [position.coords.longitude, position.coords.latitude];
        if (!this.$store.getters.location) {
          this.$store.commit('updateLocation', location);
        } else {
          this.$store.commit('updateLocation', location);
        }
        this.fetching = false;
        this.errorMessage = '';
      };
      let geoError = (error: any) => {
        this.$store.commit('updateLocation', null);
        const messageToDisplay =
          error?.message === 'User denied Geolocation'
            ? 'Location access denied'
            : error.message;
        Notification.methods.send(false, messageToDisplay, '');
        this.errorMessage = messageToDisplay;
        this.fetching = false;
      };
      const fifteenSeconds = 15_000;
      let options = {
        enableHighAccuracy: true,
        timeout: fifteenSeconds,
      };

      return navigator.geolocation.watchPosition(geoSuccess, geoError, options);
    },
  },
});
