
import GeographicService from '@/services/geo';
import { Feature, Geometry, GeometryCollection, Properties } from '@turf/turf';
import Icon from 'ol/style/Icon';
import Style from 'ol/style/Style';
import Vue from 'vue';
export default Vue.extend({
  data() {
    return {
      features: null as any,
    };
  },
  mounted() {
    this.loadHotspots();
  },
  methods: {
    async loadHotspots() {
      const team = this.$store.getters.team;
      if (!team || !team.geometry) {
        this.features = this.$store.getters.hotspots;
        return;
      }
      const filteredFeatures = this.$store.getters.hotspots.filter((feature: Feature<Geometry, Properties>) => {
        const inside = GeographicService.isInside(team.geometry, [feature.geometry.coordinates[0] as number, feature.geometry.coordinates[1] as number]);
        return inside;
      });
      this.features = filteredFeatures;
    },
    genericStyle(): (feature: any) => Style[] {
      return (feature: any) => {
        const style = new Style({
          image: new Icon({
            src: '/img/hotspot.png',
            scale: 1,
            anchor: [0.5, 1],
          }),
        });
        return [style];
      };
    },
  },
});
