
import Vue from 'vue';
import Boundaries from '@/components/Map/Boundaries.vue';
import Hotspots from './Hotspots.vue';
import Map from 'ol/Map';
import { defaults as defaultControls, Control } from 'ol/control';
import Collection from 'ol/Collection';
import { recenterMapControl } from '@/services/map/RecenterButton';
import { Notification } from '@/services/notification';
const defaultCenter = [-97, 35];
export default Vue.extend({
  components: { Boundaries, Hotspots },
  data() {
    return {
      ready: false,
      zoom: this.$store.getters.location ? 15 : 4.5,
      rotation: 0,
      center: this.$store.getters.location ?? defaultCenter,
      overlay: {
        visible: true,
        location: [0, 0],
      },
      hotspot: null as null | { name: string; description: string },
    };
  },
  computed: {
    location(): number[] {
      return this.$store.getters.location;
    },
    controls() {
      const onClick = () => this.setCenterToLocation();
      return defaultControls().extend([recenterMapControl(onClick)]);
    },
  },
  methods: {
    setCenterToLocation() {
      if (this.$store.getters.location) {
        this.center = this.$store.getters.location;
        this.zoom = this.zoom < 15 ? 15 : this.zoom;
      } else {
        Notification.methods.send(false, 'No location available', '');
      }
    },
    clickHandler(payload: any) {
      this.overlay.visible = false;
      ((this.$refs.map as any) as Map).forEachFeatureAtPixel(
        payload.pixel,
        (p: any) => this.displayPopupForFeature(p)
      );
    },
    displayPopupForFeature(feature: any) {
      const name = feature.values_.name ?? '';
      const description = feature.values_.description ?? '';
      if (!name && !description) return;
      this.hotspot = { name, description };
      if (
        !feature.values_.geometry.flatCoordinates ||
        feature.values_.geometry.flatCoordinates.length < 2
      )
        return;
      const [lat, long, _] = feature.values_.geometry.flatCoordinates;
      this.overlay = {
        location: [lat, long],
        visible: true,
      };
    },
  },
});
