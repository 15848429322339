
import { FirebaseService } from '@/services/firebase';
import Stroke from 'ol/style/Stroke';
import Style from 'ol/style/Style';
import Vue from 'vue';
export default Vue.extend({
  name: 'Boundaries',
  data() {
    return {
      url: '',
    };
  },
  mounted() {
    this.getUrl(this.$store.getters.coc);
  },
  computed: {
    features() {
      const team = this.$store.getters.team;
      if (!team) return null;
      return [team];
    },
    coc() {
      return this.$store.getters.coc;
    },
  },
  watch: {
    coc: {
      handler(newVal): void {
        this.getUrl(newVal);
      },
    },
  },
  methods: {
    async getUrl(newVal: string): Promise<void> {
      if (!newVal) return;
      const url = await FirebaseService.getCoCShapefileUrl(newVal);
      this.url = url;
    },
    style() {
      return (feature: any) => {
        // Use default stroke style and no fill style.
        return [
          new Style({
            stroke: new Stroke({
              color: '#3399CC',
              width: 2.5,
            }),
          }),
        ];
      };
    },
  },
});
