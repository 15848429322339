import Control from 'ol/control/Control';

export function recenterMapControl(onClick: Function): Control {
  const icon = document.createElement('i');
  icon.className = 'v-icon mdi mdi-crosshairs-gps';
  icon.style.fontSize = '20px';

  const button = document.createElement('button');
  button.appendChild(icon);
  button.setAttribute('id', 'selectPinsControlButton');
  button.addEventListener('click', () => onClick(), false);

  const container = document.createElement('div');
  container.className = 'ol-control ol-unselectable';
  container.style.top = '.5em';
  container.style.left = '2.6em';

  container.appendChild(button);

  return new Control({
    element: container,
  });
}
